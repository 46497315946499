/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
//@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import "@angular/material/prebuilt-themes/indigo-pink.css";

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

$mat-inpraxi: (
  50: #f0e0e6,
  100: #dab3c0,
  200: #c28096,
  300: #a94d6b,
  400: #96264c,
  500: #ff3d3d,
  600: #7c0027,
  700: #710021,
  800: #67001b,
  900: #540010,
  A100: #ff8693,
  A200: #ff5365,
  A400: #ff2037,
  A700: #ff0720,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: white,
    A400: white,
    A700: white,
  )
);

$h1-font-size: 23px;

$layout-background: #e6ede7;

$red-footer-layout-background: #ff3d3d;


$green-circle: #AEDB9A;
$red-circle: #C93D3D;
$yellow-circle: #F8F82B;

$text-color: #707070;
$input-border: $text-color;
$input-text-color: #2B2B2B;

$font-family: "Open Sans", "Roboto", HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif;

$roundCheckBox-tick-color: #707070;
$roundCheckBox-background-color: #E9EAF2;

$inpraxi-primary: mat.m2-define-palette($mat-inpraxi, 500);
$inpraxi-accent: mat.m2-define-palette(mat.$m2-gray-palette, 100);

// The warn palette is optional (defaults to red).
$inpraxi-warn: mat.m2-define-palette(mat.$m2-pink-palette, 900);
$inpraxi-theme-color: mat.m2-define-palette(mat.$m2-pink-palette);

// Create the theme object (a Sass map containing all of the palettes).
$inpraxi-theme: mat.m2-define-light-theme($inpraxi-primary, $inpraxi-accent, $inpraxi-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($inpraxi-theme);

body {
  margin: 0;
  font-family: $font-family;
  color: $text-color;
}

h1, h2, h3 {
  letter-spacing: 2px;
  color: mat.m2-get-color-from-palette($inpraxi-primary);
}

.text-primary {
  color: mat.m2-get-color-from-palette($inpraxi-primary);
}

.table-wrapper td:nth-child(1) {
  min-width: 80px;
}

.table-wrapper td:nth-child(n) {
  padding-right: 15px;
}

.standard-font {
  font-family: $font-family;
}

div.bottom-margin {
  border-bottom-width: 3.5px;
  border-bottom-style: solid;
  border-color: mat.m2-get-color-from-palette($inpraxi-primary) !important;
  width: 33px;
}

.primary-color-divider {
  border-color: mat.m2-get-color-from-palette($inpraxi-primary) !important;
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.text-black {
  color: black;
}

.text-red {
  color: red;
}

.text-green {
  color: green;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-end {
  text-align: end;
}

.text-grey {
  color: rgba(43, 43, 43, 0.77)
}

.text-medium {
  width: 134px !important;
  height: 134px !important;
  opacity: 1;
  font-size: 32px;
}

.mdl-shadow--2dp {
  box-shadow: none !important;
}

.container {
  font-family: $font-family;
  height: auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  color: $text-color !important;
}

.inner-container {
  position: relative;
  height: 100%;
  width: 100%;
}

.adjust-app-width {
  max-width: 1080px !important;
  margin: 0 auto !important;
}

.comp-title {
  margin: 24px 0 1.25em 1em;
}

.comp-email {
  margin: 24px 0 1.25em 1em;
  color: $text-color;
  font-size: 16px;
  font-weight: normal;
  background: $layout-background !important;
}

.text-bold {
  font-weight: bold;
}

.footer {
  font-family: $font-family;
  padding-bottom: 30px;
  background: $layout-background !important;
  color: $text-color !important;
  height: auto;
}

.red-footer {
  padding-bottom: 30px;
  background: $red-footer-layout-background !important;
  color: white !important;
  height: auto;
}

.content-section {
  width: 15em;
  text-align: left;
  margin-top: 8px;
}

.content-detail {
  width: 35em;
  text-align: left;
  margin-left: 5%;
  margin-right: 10%;
  margin-top: 10px;
}

.content-main {
  width: 100%;
  cursor: pointer;
}

.content-main :hover .content-main {
  color: blue;
}

.content-section-feedback {
  text-align: left;
  float: left;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 10%;
}

.content-detail-feedback {
  text-align: left;
  float: left;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 10%;
  margin-right: 10%;
}

@media (min-width: 481px) and (max-width: 959px) {
  .content-section {
    width: 100%;
    margin-left: 2vw;
  }
  .content-detail {
    margin-left: 2vw;
    width: 100%;
  }
}

@media (max-width: 489px) {
  .content-section {
    width: 100%;
    margin-left: 0;
  }
  .content-detail {
    margin-left: 0;
    width: 100%;
  }
  #profile-form .content-section p, #profile-form .content-detail p {
    padding: 0 10px;
  }
}

@media (max-width: 480px) {
  .content-section {
    margin-left: 0;
  }
  .content-detail {
    margin-left: 0;
  }
  #profile-form .content-section p, #profile-form .content-detail p {
    padding: 0 10px;
  }
}

.content-section-description {
  padding-bottom: 20px;
  width: 100%;
}

.content-form mat-form-field {
  width: 15em;
}

.pointer {
  cursor: pointer;
}

.pointer :hover .pointer {
  color: blue;
}

.divider-margin {
  margin-top: 15px !important;
  padding-bottom: 15px !important;
}

.btn-round {
  border-radius: 25px !important;
  font-family: $font-family;
  font-size: 16px;
  border-color: mat.m2-get-color-from-palette($inpraxi-primary) !important;
}

.lh-50 {
  line-height: 50px !important;
}

.lh-16 {
  line-height: 16px;
}

.divButton {
  cursor: pointer;

  a {
    text-decoration: none !important;
  }
}

.bg-grey {
  background: $layout-background;
}

.bg-white {
  background: white;
}

.svgIcon {
  width: 24px;
  height: 24px;
}

.navi-button {
  min-width: 0 !important;
}

.maxWidth {
  max-width: 1080px !important;
}

.roundCorner {
  border-radius: 10px !important;
}

.errInput {
  border: 1px solid red !important;
}

.errText {
  color: red;
}

.red {
  fill: $red-circle;
}

.green {
  fill: $green-circle;
}

.yellow {
  fill: $yellow-circle;
}

.mat-mdc-dialog-container {
  border-radius: 4px;
  background: $layout-background;
}

.mat-dialog-actions {
  justify-content: flex-end !important;
}

mat-button-toggle-group {
  margin-left: 12px;
  border-radius: 25px !important;
  height: 27px;
  align-items: center;
}

.mat-button-toggle {
  color: rgba(43, 43, 43, 0.77)
}

mat-sidenav-content {
  background: $layout-background !important;
}

.mdc-list-item__content {
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
}

mat-list-item.rm-list-item-padding .mdc-list-item__content {
  padding: 0 !important;
}

mat-list-item.no-padding-search > .mdc-list-item__content {
  padding: 0 !important;
}

mat-list-item {
  padding: 0 !important;
}

.mat-drawer-container {
  background-color: white;
}

.mat-drawer-inner-container {
  background-color: mat.m2-get-color-from-palette($mat-inpraxi, 500);
  color: white !important;
}

.mat-menu-content {
  background-color: mat.m2-get-color-from-palette($mat-inpraxi, 400);
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.mat-menu-item {
  color: white;
}

.table-overflow {
  display: block !important;
  overflow: hidden;
  align-self: center;
  text-overflow: ellipsis;
  padding: 2px 1px 2px 1px;
  white-space: nowrap;
}

.grid-table-cell {
  overflow: hidden;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.grid-table {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 35% 65%;
  align-items: center;
}

.custom-input {
  border-radius: 25px;
  border: 1px solid $input-border;
  height: 45px;
  width: 100%;
  font-size: 16px;
  text-indent: 20px;
  letter-spacing: 1.2px;
  color: $input-text-color;
  cursor: text;
}

a {
  color: rgba(43, 43, 43, 0.77)
}

a:active {
  color: rgba(43, 43, 43, 0.77)
}

.width-99 {
  width: 99%;
}

a:visited {
  color: rgba(43, 43, 43, 0.77)
}

/* Customized Links CSS ends here */

/* Profile Image CSS starts here */
.profile-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-border-radius: 99em;
  -moz-border-radius: 99em;
  border-radius: 99em;
  display: inline-block;
}

.profile-image-container {
  width: 30%;
}

.up-pic-size {
  margin-top: 2vh;
}

.up-pic-size .profile-image.ng-star-inserted {
  width: 134px;
  height: 134px;
  opacity: 1;
}


.help-contact .profile-image.ng-star-inserted {
  width: 150px;
  height: 150px;
  opacity: 1;
}

/* Profile Image CSS ends here */

/* Google login UI CSS starts here */
#firebaseui-auth-container .firebaseui-container {
  max-width: 635px !important;
  padding: 0 !important;
  margin: 0 !important;
  color: $text-color !important;
}

#firebaseui-auth-container .firebaseui-input {
  color: $text-color !important;
}

#firebaseui-auth-container .firebaseui-container h1 {
  color: mat.m2-get-color-from-palette($inpraxi-primary);
  font-size: $h1-font-size;
  text-transform: uppercase;
  display: inline-block;
  background-image: linear-gradient(to right, mat.m2-get-color-from-palette($inpraxi-primary), mat.m2-get-color-from-palette($inpraxi-primary));
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: 25% 4px;
  transition: background-size .5s ease;
  padding-bottom: 15px;
  margin-bottom: 0.5em;
  font-family: $font-family;
}

div.firebaseui-card-header {
  padding-left: 0 !important;
  padding-top: 0 !important;
}

div.firebaseui-card-content {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

div.firebaseui-form-actions button {
  background-color: mat.m2-get-color-from-palette($inpraxi-primary) !important;
  border-radius: 25px !important;
  letter-spacing: 1.28px !important;
  font-family: $font-family;
}

.firebaseui-form-actions .mdl-button--primary {
  background-color: transparent !important;
  color: $text-color;
  border: 1px solid $text-color;
}

.firebaseui-textfield.mdl-textfield .firebaseui-label::after {
  background-color: mat.m2-get-color-from-palette($inpraxi-primary);
}

div.firebaseui-form-links a {
  color: $text-color;
}

div.firebaseui-card-actions {
  padding-left: 0;
  padding-right: 0;
}

/* Google login UI CSS ends here */

/* Customized Radio Button CSS starts here */
.radioButton {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 30px;
}

//Hide the browser's default radio button
.radioButton input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

//Create a custom radio button
.radioBtn {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: white;
  border: 1px solid grey;
  border-radius: 50%;
}

//On mouse-over, add a grey background color
.radioButton:hover input ~ .radioBtn {
  background-color: #ccc;
}

//When the radio button is checked, add a blue background
.radioButton input:checked ~ .radioBtn {
  //background-color: #84002c;
}

//Create the indicator (the dot/circle - hidden when not checked)
.radioBtn:after {
  content: "";
  position: absolute;
  display: none;
}

//Show the indicator (dot/circle) when checked
.radioButton input:checked ~ .radioBtn:after {
  display: block;
}

//Style the indicator (dot/circle) */
.radioButton .radioBtn:after {
  top: 5px;
  left: 5px;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  background: black;
}

/* Customized Radio Button CSS ends here */

/* Customized (cancel booking) textarea starts here */
.cancelBookingTextArea {
  padding-left: 15px;
  padding-top: 10px;
  resize: none;
  width: 100%;
  display: flex;
  border: none;
  color: $text-color;
  font-family: $font-family;
}

/* Customized (cancel booking) textarea ends here */

/* (Cancel/deny booking) container starts here */
.popupWindowContainer {
  height: auto;
  font-size: 16px;
  letter-spacing: 0.16px;
  opacity: 1 !important;
  font-family: $font-family !important;
  background: $layout-background;
  color: $text-color;
  padding: 24px;

  span.small {
    text-align: left;
    font-family: $font-family;
    letter-spacing: 0.11px;
    font-size: 11px;
  }

  .mat-mdc-dialog-title {
    padding: 0px !important;
    margin-bottom: 20px !important;
  }
}

.popupWindowMargin {
  margin-left: 17%;
  @media screen and (max-width: 753px) {
    margin-left: 8% !important;
  }
}

/* (Cancel booking) container ends here */

/* Customized Round Checkbox starts here */
.roundCheckBox label {
  //background-color: $roundCheckBox-background-color;
  border-radius: 50%;
  cursor: pointer;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px;
  border: 1px solid grey;
}

.roundCheckBox label:after {
  border: 2px solid black;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 4px;
  opacity: 0;
  position: absolute;
  top: 5px;
  transform: rotate(-45deg);
  width: 12px;
}

.roundCheckBox input[type="checkbox"] {
  visibility: hidden;
}

.roundCheckBox input[type="checkbox"]:checked + label {
  -webkit-transition: all 0.50s;
  -moz-transition: all 0.50s;
  -o-transition: all 0.50s;
  transition: all 0.50s;

  //background-color: #E9EAF2
}

//On mouse-over, add a grey background color
.roundCheckBox input[type="checkbox"]:hover + label {
  background-color: #ccc;
}

.roundCheckBox input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.roundCheckBox {
  position: relative;
}


/* Customized Round Checkbox ends here */

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.ls-1 {
  letter-spacing: 1px;
}

.ls-2 {
  letter-spacing: 2px;
}

.ls-3 {
  letter-spacing: 3px;
}

.h-40 {
  height: 40px;
}

.h-50 {
  height: 50px;
}

.w-60 {
  width: 60px !important;
}

.w-180 {
  width: 180px !important;
}

.w-330 {
  width: 330px !important;
  margin-right: 55px !important;
}

.w-380 {
  width: 380px !important;
}

.w-455 {
  width: 455px !important;
}

.min-w90 {
  min-width: 90px !important;
}

.full-width {
  width: 100% !important;
}


/* Margins */

.mltr-10 {
  margin: 0 10px;
}

.m-auto {
  margin: auto !important;
}

.m-0 {
  margin: 0 !important;
}

.m-10 {
  margin: 10px !important;
}

/* Margin Top */

.mt-0 {
  margin-top: 0 !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-55 {
  margin-top: 55px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mt-65 {
  margin-top: 65px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mt-90 {
  margin-top: 90px !important;
}

/* Margin Right */

.mr-5 {
  margin-right: 5px !important;
}

.mr-7 {
  margin-right: 7px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

/* Margin Bottom */

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

/* Margin Left */

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-25 {
  margin-left: 25px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.ml-35 {
  margin-left: 35px;
}

/* Padding */

.p-tb10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.p-0 {
  padding: 0 !important;
}

.p-5 {
  padding: 5px;
}

.p-10 {
  padding: 10px !important;
}

.p-15 {
  padding: 15px !important;
}

.p-20 {
  padding: 20px !important;
}

.p-25 {
  padding: 25px !important;
}

.p-35 {
  padding: 35px !important;
}

/* Padding-Top */

.pt-25 {
  padding-top: 25px !important;
}

.pt-35 {
  padding-top: 35px;
}

.pt-90 {
  padding-top: 90px;
}

/* Padding Right */

.pr-10 {
  padding-right: 10px;
}

.pr-20 {
  padding-right: 20px;
}

/* Padding Bottom */

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pb-35 {
  padding-bottom: 35px !important;
}

.pb-45 {
  padding-bottom: 60px;
}

.pb-150 {
  padding-bottom: 150px !important;
}

/* Padding Left */

.pl-15 {
  padding-left: 15px !important;
}

.pl-10 {
  padding-left: 10px;
}


.pl-20 {
  padding-left: 20px;
}

.pl-25 {
  padding-left: 25px !important;
}

.lowercase {
  text-transform: lowercase !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.noPointerEvents {
  pointer-events: none !important;
}

.textEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.br-1 {
  border-radius: 1em !important;
}


.custom-mat-list-item{
  cursor: pointer !important;
  white-space: nowrap;
  line-height: 3em;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
  font-size: 1em;
  height: 3em;
  padding: 0 0.38em;
  text-decoration: none;
  max-width: 100%;
  width: 100% !important;
  position: relative;
  outline: 0;
  color: $text-color !important;
  &:hover{
    background-color: #f5f5f5 !important;
  }
}
  .navList {
    color: $text-color !important;
  }


/* Default Theme Start */
.output-container {
  background: white !important;
  height: auto;
  width: 100%;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 22px;
  opacity: 1 !important;
  font-family: "Open Sans", "Roboto", HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif;
  max-width: 1080px;
}

.output-container h1 {
  font-size: 23px;
  font-weight: Bold;
  letter-spacing: 2.76px;
  text-align: left;
  color: mat.m2-get-color-from-palette($inpraxi-primary);
  text-transform: uppercase;
  background-image: linear-gradient(to right, mat.m2-get-color-from-palette($inpraxi-primary), mat.m2-get-color-from-palette($inpraxi-primary));
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: 41px 4px;
  transition: background-size .5s ease;
  padding-bottom: 15px;
  margin-bottom: 0.5em;
}


.check-icon {
  position: absolute;
  right: 10px;
}

.heading1-npxpage {
  font-size: 23px;
  font-weight: Bold;
  letter-spacing: 2.76px;
  text-align: left;
  color: mat.m2-get-color-from-palette($inpraxi-primary);
  text-transform: uppercase;
  background-image: linear-gradient(to right, mat.m2-get-color-from-palette($inpraxi-primary), mat.m2-get-color-from-palette($inpraxi-primary));
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: 41px 4px;
  transition: background-size .5s ease;
  padding-bottom: 15px;
  margin-bottom: 0.5em;
}


.heading1-npx-title {
  font-size: 23px;
  font-weight: Bold;
  letter-spacing: 2.76px;
  text-align: left;
  color: mat.m2-get-color-from-palette($inpraxi-primary);
  text-transform: uppercase;
  background-image: none;
  padding-bottom: 15px;
  margin-bottom: 0.5em;
}

.output-container inpraxi-npx-title h1 {
  font-size: 23px;
  font-weight: Bold;
  letter-spacing: 2.76px;
  text-align: left;
  color: mat.m2-get-color-from-palette($inpraxi-primary);
  text-transform: uppercase;
  background-image: none;
  padding-bottom: 15px;
  margin-bottom: 0.5em;
}

p {
  overflow-wrap: break-word !important;
  word-break: break-word !important;
  /*max-width: 780px !important;*/
  /*min-width: 100px !important;*/
  margin: 0;
  color: $text-color !important;
}

.item-wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex-wrap {
  flex-wrap: wrap;
}

inpraxi-npx-additional-text h4 {
  margin: 0;
  color: $text-color !important;
}

inpraxi-npx-additional-text[ng-reflect-section-i-d="Program"] h2,
h3,
h4,
h5,
h6 {
  line-height: 20px !important;
  color: $text-color !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: mat.m2-get-color-from-palette($inpraxi-primary) !important;
}

.logoIcon {
  max-width: 230px !important;
  height: 45px;
}

inpraxi-npx-additional-text[ng-reflect-section-i-d="Program"] tr > th {
  text-align: center;
}

inpraxi-npx-additional-text[ng-reflect-section-i-d="Program"] tr {
  line-height: 20px;
  height: 30px !important;
}

inpraxi-npx-additional-text[ng-reflect-section-i-d="Program"] tr > td {
  border: 6px solid #ffffff;
  border-left-width: 0;
  border-right-width: 0;
}

inpraxi-npx-additional-text[ng-reflect-section-i-d="Program"] td:first-child {
  color: $text-color !important;
  background-color: $layout-background;
  padding: 6px !important;
  width: 96px !important;
  text-align: center !important;
  font-weight: Bold;
  font-size: 16px;
}

inpraxi-npx-additional-text[ng-reflect-section-i-d="Program"] td:nth-child(2) {
  color: $text-color !important;
  padding: 16px !important;
}

.page-container {
  font-family: "Open Sans", HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  height: auto;
  display: flex;
  flex-direction: column;
  /*margin-top: 20px;*/
}

.lecturer-container {
  width: 380px !important;
}

.lecturer-container div div {
  padding-left: 9px;
  line-height: 20px;
}

inpraxi-npx-avatar.up-pic-size .profile-image.ng-star-inserted {
  width: 134px !important;
  height: 134px !important;
  opacity: 1;
}

.text-medium {
  width: 134px !important;
  height: 134px !important;
  background-size: cover;
  -webkit-border-radius: 99em;
  -moz-border-radius: 99em;
  font-size: 32px;
}

.text-color {
  color: $text-color !important;
}

.hotel-cost-width {
  width: 180px !important;
  height: 22px !important;
}

.summary-width {
  max-width: 250px !important;
}

.hotel-summary {
  text-align: left;
  font-size: 10px;
  letter-spacing: 0.1px;
  line-height: 16px;
  color: $text-color !important;
  margin-top: 20px !important;
  max-width: 650px !important;
  opacity: 1;
}

.adjust-table {
  margin: auto;
  border-radius: 1em;
}

.direction-row {
  display: flex;
  flex-direction: row;
}

.direction-column {
  display: flex;
  flex-direction: column;
}

@media print and (color) {
  * {
    -webkit-print-color-adjust: exact;
    overflow: visible !important;
  }

  .direction-row {
    -webkit-flex-direction: row;
    flex-direction: row;
  }

  .direction-column {
    -webkit-flex-direction: column;
    flex-direction: column;
  }
}

/* Default Theme End */

.move-right {
  float: right !important;
}

.move-left {
  float: left !important;
}

.no-cursor {
  cursor: default !important;
}

.footer-grid-container {
  font-family: $font-family;
  font-size: 13px;
  color: #dddddd;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
  "footer-heading1 footer-heading2"
  "footer-link1 footer-link2"
  "footer-link5 footer-link4"
  "footer-link5 footer-link6"
  "footer-link5 footer-link8"
  "footer-link5 footer-link10"
  "footer-link5 footer-link12";
  grid-gap: 5px;
}

.footer-heading1 {
  grid-area: footer-heading1;
  color: white;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1.3em;
}

.footer-heading2 {
  grid-area: footer-heading2;
  color: white;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1.3em;
}

.footer-link1 {
  grid-area: footer-link1;
}

.footer-link2 {
  grid-area: footer-link2;
}

.footer-link4 {
  grid-area: footer-link4;
}

.footer-link5 {
  grid-area: footer-link5;
  line-height: 1.8;
}

.footer-link6 {
  grid-area: footer-link6;
}

.footer-link8 {
  grid-area: footer-link8;
}

.footer-link10 {
  grid-area: footer-link10;
}

.footer-link12 {
  grid-area: footer-link12;
}


.red-footer-links {
  cursor: pointer;

  a {
    text-decoration: none !important;
    color: #dddddd;
  }
}

.red-footer-email-links {
  cursor: pointer;

  a {
    color: #dddddd;
  }
}

@media only screen and (max-width: 1080px) {
  .footer-grid-container {
    margin-left: 2vw;
  }
}

@media only screen and (max-width: 600px) {
  .footer-grid-container {
    grid-template-columns: 1fr;
    grid-template-areas:
        "footer-heading1"
        "footer-link1"
        "footer-link5"
        "footer-heading2"
        "footer-link2"
        "footer-link4"
        "footer-link6"
        "footer-link8"
        "footer-link10"
        "footer-link12";
    margin-left: 2vw;
  }
  .footer-heading2 {
    margin-top: 40px;
  }
}

.flex-wrapper{
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}

.flex-wrapper .content{
  flex-grow: 1;
}
